'use client';

import React from 'react';
import { motion } from 'framer-motion';

const LoadingLogo = () => {
  return (
    <div className="relative w-full h-full flex items-center justify-center">
      {/* Outer spinning circle */}
      <motion.div
        className="absolute inset-0 rounded-full border-4 border-blue-500/20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      />

      {/* New improved smooth spinning ring */}
      <motion.div
        className="absolute inset-0 rounded-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          background: 'conic-gradient(from 0deg, #2563eb, transparent 70%)', // Darker blue and adjusted gradient stop
          animation: 'spinLoader 3s linear infinite',
          boxShadow: '0 0 15px 5px rgba(59, 130, 246, 0.3)', // Added glow effect
        }}
      />
      {/* Logo - adjusted to be centered */}
      <div className="relative flex items-center justify-center w-3/4 h-3/4">
        <svg
          viewBox="0 0 425 209"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Static W letters with fade in */}
          <motion.path
            d="M371.052 2.0361C388.542 2.0361 406.032 2.0361 424.052 2.0361C423.411 6.5261 366.173 194.241 362.052 208.036C349.182 208.036 336.312 208.036 323.052 208.036C322.125 203.246 309.666 139.367 309.052 137.036C307.57 143.559 296.627 200.16 295.052 208.036C282.512 208.036 269.972 208.036 257.052 208.036C250.168 187.383 234.335 135.582 235.5 133C237.582 130.965 262.15 104.194 268 101.5C270.777 112.475 276.486 126.01 279.052 137.036C281.023 128.612 287.156 94.1171 287.326 92.6081C288.328 85.3521 318.155 55.7631 322.052 52.0361C324.976 54.9591 339.002 133.073 340.052 136.036C340.264 135.089 369.99 6.4351 371.052 2.0361Z"
            fill="#FEFEFE"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
          <motion.path
            d="M53 1C35.51 1 18.02 1 3.05176e-05 1C0.64103 5.49 57.879 193.205 62 207C74.87 207 87.74 207 101 207C101.927 202.21 114.386 138.331 115 136C116.482 142.523 127.425 199.124 129 207C141.54 207 154.08 207 167 207C173.884 186.347 189.165 135.582 188 133C185.918 130.965 159.5 104.5 154.5 99.5C151.723 110.475 147.566 124.974 145 136C143.029 127.576 136.896 93.081 136.726 91.572C135.724 84.316 105.897 54.727 102 51C99.076 53.923 85.05 132.037 84 135C83.788 134.053 54.062 5.399 53 1Z"
            fill="#FEFEFE"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          />
          <motion.path
            d="M185.689 2.7161C194.637 1.68 226.948 1.68 233.5 2.7161C236 9.5 251.238 48.478 252.5 53C251.21 54.375 220.137 85.18 218.137 85.18C217.442 81.5809 211.337 53.82 209.637 46.68C209.137 51.18 203.567 81.1379 203.137 85.18C201.021 82.687 171.357 51.678 168.5 48.5C169.601 44.165 182.637 8.18 185.689 2.7161Z"
            fill="#FEFEFE"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          />

          {/* Animated watch hands - centered around 212.5, 104.5 (center of the SVG) */}
          <g style={{ transformOrigin: '212.5px 104.5px' }}>
            {/* Minute hand (left blue section) */}
            <motion.path
              d="M129 18C130.719 19.679 194.061 83.784 196 86C191.214 83.868 174.452 100.472 177 111C163 97 110.83 43.742 106 39C105.5 36.5 96.5 16.5 94 5C104.5 7.5 125 17 129 18Z"
              fill="#0381CB"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, delay: 0.5 }}
              style={{
                transformOrigin: '212.5px 104.5px',
                animation:
                  'rotateMinuteHand 1s cubic-bezier(0.87, 0, 0.13, 1) infinite',
              }}
            />

            {/* Hour hand (right blue section) */}
            <motion.path
              d="M310.19 0.5C308.471 2.179 227.129 84.284 225.19 86.5C229.976 84.368 246.738 100.972 244.19 111.5C258.19 97.5 328.36 26.242 333.19 21.5C328.977 16.487 313.19 0.5 310.19 0.5Z"
              fill="#0381CB"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              style={{
                transformOrigin: '212.5px 104.5px',
                animation:
                  'rotateHourHand 2s cubic-bezier(0.87, 0, 0.13, 1) infinite',
              }}
            />
          </g>

          {/* Center point - updated to exact center */}
          <motion.circle
            cx="212.5"
            cy="104.5"
            r="16.4209"
            fill="#0682CC"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
          />

          <style>
            {`
                    @keyframes rotateHourHand {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                    
                    @keyframes rotateMinuteHand {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                    
                    @keyframes spinLoader {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                `}
          </style>
        </svg>
      </div>

      {/* Inner spinning light */}
      <motion.div
        className="absolute rounded-full bg-blue-500/10 w-1/3 h-1/3"
        initial={{ opacity: 0 }}
        animate={{
          opacity: [0.2, 0.5, 0.2],
          scale: [0.8, 1, 0.8],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      />
    </div>
  );
};

export default LoadingLogo;

// Export a full page loading screen component
export const FullPageLoading = ({
  message = 'Loading...',
}: {
  message?: string;
}) => {
  return (
    <div className="fixed inset-0 bg-gradient-to-b from-slate-950 via-slate-900 to-slate-900/95 z-[9999] flex flex-col items-center justify-center">
      <motion.div
        className="w-24 h-24 mb-4"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.5 }}
      >
        <LoadingLogo />
      </motion.div>

      <motion.p
        className="text-white/70 text-sm"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.3 }}
      >
        {message}
      </motion.p>
    </div>
  );
};

// Additional loading wrapper with size options
export const LoadingWrapper = ({
  message = 'Loading...',
  size = 'medium',
  className = '',
  showMessage = true,
}: {
  message?: string;
  size?: 'small' | 'medium' | 'large' | 'full';
  className?: string;
  showMessage?: boolean;
}) => {
  const sizeClasses = {
    small: 'w-12 h-12',
    medium: 'w-24 h-24',
    large: 'w-32 h-32',
    full: 'w-40 h-40',
  };

  return (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <motion.div
        className={`${sizeClasses[size]} mb-2`}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.5 }}
      >
        <LoadingLogo />
      </motion.div>

      {showMessage && (
        <motion.p
          className="text-white/70 text-sm"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.3 }}
        >
          {message}
        </motion.p>
      )}
    </div>
  );
};
