//client-layout.tsx
'use client';

import React, { useEffect, useState } from 'react';
import { UIProvider, useUI } from '@/context/UIContext';
import { AuthProvider } from '@/lib/AuthContext';
import { NotificationProvider } from '@/contexts/NotificationContext';
import { CartProvider } from '@/contexts/CartContext';
import { GoogleAnalytics } from '@next/third-parties/google';
import { AnimatePresence, motion } from 'framer-motion';
import LoadingLogo from '@/components/component/loading-screen/animated-logo';
import { SupportMain } from '@/components/component/support/support-main';
import { SupportButton } from '@/components/component/support/SupportButton';
import { usePathname, useSearchParams } from 'next/navigation';
import { Navbar1 } from '@/components/component/navbar/navbar';

// Wrapper component for providers - ensures same order as _app.tsx

export function ClientLayout({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <UIProvider>
      <GoogleAnalytics gaId="G-L6K1F25D5F" />

      <AuthProvider>
        <NotificationProvider>
          <CartProvider>
            <ClientContent>{children}</ClientContent>
          </CartProvider>
        </NotificationProvider>
      </AuthProvider>
    </UIProvider>
  );
}

// Separate component for content with hooks

function ClientContent({ children }: { children: React.ReactNode }) {
  const { isProfileOpen } = useUI();

  const [isRouteChanging, setIsRouteChanging] = useState(false);

  const [showSupportMain, setShowSupportMain] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  const pathname = usePathname();

  // Handle scroll locking when profile panel or support is open
  useEffect(() => {
    const bodyElement = document.body;

    // Save the current scroll position
    const scrollY = window.scrollY;

    if (isProfileOpen || showSupportMain) {
      // Store current scroll position
      bodyElement.style.position = 'fixed';
      bodyElement.style.top = `-${scrollY}px`;
      bodyElement.style.width = '100%';
      bodyElement.style.overflowY = 'scroll'; // Use scroll instead of hidden to prevent layout shift
    } else {
      // Restore scroll position when closing
      bodyElement.style.position = '';
      bodyElement.style.top = '';
      bodyElement.style.width = '';
      bodyElement.style.overflowY = '';

      // Scroll back to the original position
      window.scrollTo(0, parseInt(bodyElement.style.top || '0') * -1);
    }

    return () => {
      // Clean up in case component unmounts while panel is open
      bodyElement.style.position = '';
      bodyElement.style.top = '';
      bodyElement.style.width = '';
      bodyElement.style.overflowY = '';
    };
  }, [isProfileOpen, showSupportMain]);

  // Simplified route change detection

  //removed small delay for route change to ensure loading is as fast as possible between routes
  // useEffect(() => {
  //   setIsRouteChanging(true);

  //   // Add a small delay to allow for the route change

  //   const timer = setTimeout(() => {
  //     setIsRouteChanging(false);
  //   }, 800);

  //   return () => clearTimeout(timer);
  // }, [pathname]);

  const handleSupportButtonClick = () => {
    setShowSupportMain(!showSupportMain);
  };

  // List of pages that have their own navbar implementations

  const pagesWithCustomNavbar = ['/', '/dashboard']; // Add other pages as needed

  const shouldShowGlobalNavbar = !pagesWithCustomNavbar.includes(
    pathname || ''
  );

  // Determine if we're on the home page
  const isHomePage = pathname === '/';

  return (
    <div className="relative">
      <AnimatePresence mode="wait">
        {isRouteChanging && (
          <motion.div
            key="loading"
            className="fixed inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-gray-800/95 z-[9999] flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <motion.div
              className="w-24 h-24"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3, ease: 'easeOut' }}
            >
              <LoadingLogo />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Only show global navbar on pages that don't have their own navbar */}

      {shouldShowGlobalNavbar && (
        <div
          className={`sticky top-0 z-50 ${isSticky ? 'bg-slate-900/70 backdrop-blur-lg' : ''}`}
        >
          <Navbar1 isSticky={isSticky} isTransparent={false} />
        </div>
      )}

      {/* Main content - only apply blur on non-home pages (as home page handles its own blur) */}
      {isHomePage ? (
        <div className="min-h-screen">{children}</div>
      ) : (
        <motion.div
          className="min-h-screen isolation"
          animate={{
            filter: isProfileOpen ? 'blur(4px)' : 'blur(0px)',
          }}
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
        >
          {children}
        </motion.div>
      )}

      {/* Support components and other UI elements */}

      <AnimatePresence>
        {showSupportMain && <SupportMain onClose={handleSupportButtonClick} />}
      </AnimatePresence>

      <SupportButton
        onClick={handleSupportButtonClick}
        isVisible={!showSupportMain}
      />

      {/* Global CSS to prevent ProfilePanel from being blurred */}
      <style dangerouslySetInnerHTML={{
        __html: `
        .profile-panel,
        .profile-trigger {
          filter: none !important;
          backdrop-filter: none !important;
        }
      `}} />
    </div>
  );
}
